.layout-wrapper .layout-sidebar .sidebar-logo .p-link.sidebar-anchor {
    margin-right: 20px;
    display: inline-block;
    background-color: #ffffff;
}


.layout-wrapper .layout-sidebar .sidebar-logo .p-link {
    display:none;
}

.layout-wrapper.layout-wrapper-static .layout-sidebar .sidebar-logo .p-link {
    display: inline;
}

.layout-wrapper.layout-wrapper-static .layout-sidebar .sidebar-logo .p-link {
    max-width: 70%;
    padding-top: 10px;
}

.layout-wrapper.layout-wrapper-static .layout-sidebar .sidebar-logo .p-link > img {
    max-width: 100%;
    max-height: 40px;
    width: auto;
}

.layout-wrapper.layout-wrapper-static .layout-sidebar .sidebar-logo .p-link.sidebar-anchor {
    margin-right: 14px;
}

.layout-wrapper .layout-main .layout-footer > a > img {
    max-width: 11%;
    max-height: 55px;
    width: auto;
    height: auto;
}

@media screen and (min-width: 600px) {
    .layout-wrapper .layout-main .layout-footer > a > img {
        max-width: 15%;
        max-height: 55px;
        width: auto;
        height: auto;
    }
}

@media screen and (max-width: 600px) {
    .layout-wrapper .layout-main .layout-footer > a > img {
        max-width: 80%;
        max-height: 55px;
        width: auto;
        height: auto;

    }

    .layout-wrapper .layout-main .layout-footer  .footer-text-right {
        display: none;
    }
}

.layout-wrapper .layout-sidebar .layout-menu li > a svg {
    color: #616161;
    float: right;
    width: 20px;
    height: 20px;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
}