header {
  height:170px;
  color:#FFF;
  font-size:20px;
  font-family:Sans-serif;
  background:#009688;
  padding-top:30px;
  padding-left:50px;
}
.container-attachment-buttons {
  width:90px;
  height:240px;
  position:absolute;
  right: -0.8em;
  bottom:0;
  max-height: 50px;
  margin-bottom: 2em;
}
.botonF6 {
  width:60px;
  height:60px;
  border-radius:100%;
  background:#F44336;
  right:0;
  bottom:0;
  position:absolute;
  margin-right:16px;
  margin-bottom:16px;
  border:none;
  outline:none;
  color:#FFF;
  font-size:36px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition:.3s;  
}
span {
  transition:.5s;  
}
.botonF6:hover span {
  transform:rotate(360deg);
}
.botonF6:active {
  transform:scale(1.1);
}
.btn-componente {
  width:40px;
  height:40px;
  border-radius:100%;
  border:none;
  color:#FFF;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  font-size:28px;
  outline:none;
  position:absolute;
  right:0;
  bottom:0;
  margin-right:88.5px;
  transform:scale(0);
  cursor: pointer;
}
.botonF7 {
  background:#b421f3;
  margin-bottom:20%;
  transition:0.5s;
}
.botonF8 {
  background:#2268ff;
  margin-bottom:70%;
  transition:0.7s;
}
.botonF9 {
  background:#ffaa22;
  margin-bottom:122%;
  transition:0.9s;
}
.botonF10 {
  background:#673AB7;
  margin-bottom:170%;
  transition:0.99s;
}
.botonF11 {
  background:#a93ab7;
  margin-bottom:220%;
  transition:0.9s;
}
.botonF12 {
  background: #b92073;
  margin-bottom:270%;
  transition:0.9s;
}
.btn-attachment-animation {
  transform:scale(1);
}
