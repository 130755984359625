.center {
    text-align: center;
}

.photo {
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    align-self: flex-start;
}

.text-div {
  width: auto;
  position: absolute;
  padding: 50px;
  transform: translate(20%, -55%);
}

.title-div-center {
  width: auto;
  position: absolute;
  top: -5%;
  transform: translate(-15%, -50%);
}

.containter-center {
  position: relative;
  display: flex;
  justify-content: center; /* Centrado horizontal */
  align-items: center; /* Centrado vertical */
  bottom: 20px;
}

.text-button-center {
  width: auto;
  position: absolute;
  top: 90%;
  left: 50%;
  color: #ffffff;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.text-div-center {
  width: auto;
  position: absolute;
  top: 88%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  font-size: 15px;
}

.overlay-button-reset {
  position: absolute;
  padding: 10px 10px;
  background-color: #7CB342;
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 2;
}

.text-click-center {
  width: auto;
  position: absolute;
  top: 94%;
  left: 50%;
  color: #7CB342;
  cursor: pointer;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.img-div {
  width: auto;
  position: absolute;
  top: 45%;
  left: 25%;
  transform: translate(-45%, -50%);
}

.img-grayscale {
  position: relative;
  filter: grayscale(100%);
  bottom: 8px;
}

.img-grayscale-show {
  filter: blur(1.5px);
}

.qr-spinner {
	font-size: 4em;
	margin-top: 78px;
	margin-bottom: 20px;
}

.qr-backdrop {
	position: fixed;
	box-sizing: border-box;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1020;
	background: rgba(0,0,0,0.5) no-repeat center center fixed;
	overflow-y: hidden;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	outline: 0;
}

.qr-containter {
	position: relative;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 50px;
  width: 50%;
	margin: 30px auto;
	background-color: #fff;
	border: 1px solid rgba(0,0,0,.2);
	border-radius: 6px;
	align-self: center;
}

.qr-content {
	position: relative;
	padding: 200px;
}

.qr-content p{
	color: #333 !important;
	font-size: 16px;
	margin-top: 15px;
}

.qr-separator{
  margin-left: 0px;
}

@media(min-width: 1500px) {
	.qr-separator{
    margin-left: 10px;
  }
}

@media(min-width: 1800px) {
	.qr-separator{
    margin-left: 40px;
  }
  .title-div-center {
    transform: translate(5%, -50%);
  }
}

@media(min-width: 2000px) {
	.qr-separator{
    margin-left: 90px;
  }
}

@media(max-width: 1300px) {
	.qr-containter {
		position: relative;
		width: auto;
		margin: 10px;
		background-color: #fff;
		border: 1px solid rgba(0,0,0,.2);
		border-radius: 6px;
	}
  .img-div {
    width: auto;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title-div-center {
    width: auto;
    position: absolute;
    top: 5%;
    left: 10%;
    transform: translate(-0%, -50%);
  }
}

.qr-containter h4 {
  line-height: 10pt;
  font-size: 18px;
}

.qr-containter p {
	font-size: 22px;
  text-align: center;
}

.custom-qr-close {
  position: absolute;
  top: -6%;
  transform: translateY(-50%);
  right: -4%;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 900px) {
  .title-div-center {
    transform: translate(-5%, -50%);
  }
}

@media (max-width: 450px) {
  .custom-qr-close {
    right: 10%;
  }
}

.custom-close {
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
  right: 15px;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.datatable-filter-wa-mobile .p-paginator .p-paginator-current {
  margin-left: auto;
}

.datatable-filter-wa-mobile .p-progressbar {
  height: .5rem;
  background-color: #D8DADC;
}

.datatable-filter-wa-mobile .p-progressbar .p-progressbar-value {
  background-color: #607D8B;
}

.datatable-filter-wa-mobile .table-header {
  display: flex;
  justify-content: space-between;
}

.datatable-filter-wa-mobile .p-datepicker {
  min-width: 25rem;
}

.datatable-filter-wa-mobile .p-datepicker td {
  font-weight: 400;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
  text-align: left;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
  cursor: auto;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}

.datatable-filter-wa-mobile .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 750px) {
  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
      display: none !important;
  }

  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
      border-bottom: 1px solid var(--layer-2);
  }

  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
      text-align: center;
      display: block;
      border: 0 none !important;
      width: 100% !important;
      float: left;
      clear: left;
      border: 0 none;
  }

  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
      padding: .4rem;
      min-width: 30%;
      display: inline-block;
      margin: -.4rem 1rem -.4rem -.4rem;
      font-weight: bold;
  }

  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
      margin-top: .5rem;
  }
}