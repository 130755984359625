.template-value{
    width: 80%;
    margin: 0px 10px 0px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.template-value-img {
    border: none;
    background: none;
}

.template-value-img:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
}
