.conversation-init {
  padding: 0 10px 0 10px;
  flex-direction: column;
  max-width: 100%;
}

@media (max-width: 1024px) {
  .conversation-init {
    padding: 0 20px 0 150px;
    max-width: 75%;
  }
}

.conversation-init-input {
  background: #f4f4f8;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  width: 98%;
}

.conversation-init-input.loading {
  background-color: #f4f4f8;
  background-image: url("../../../assets/images/loading.gif");
  background-size: 25px 25px;
  background-position:right center;
  background-repeat: no-repeat;
}

.conversation-init-input::placeholder {
  text-align: center;
}

.conversation-init-input:focus::placeholder {
  text-align: left;
}

.intl-tel-input-custom {
  background: #f4f4f8;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  width: 98%;
}

.intl-tel-container-custom {
  width: 30em;
  max-width: 100%;
  margin-left: 0.5em;
  margin-bottom: 0.5em;
}