.login-body .login-panel .login-panel-header .logo-login {
    height: 35px;
    width: auto;
    margin: 5px 0 5px 0;
}
body .md-inputfield .label-top {
    top: -20px
}

.body-container{
  position: relative;
  background-image: none;
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
  margin: -1px 0 50px;
}

.body-container * {
  box-sizing: border-box;
}

.body-container h1 {
  font-weight: bold;
  margin: 0;
}

.body-container a {
  position: relative;
  margin-top: 30px;
  top: 80px;
}

.body-container h2 {
  text-align: center;
}

.body-container p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.body-container span {
  font-size: 12px;
}

.body-container button {
  border-radius: 20px;
  border: 1px solid #7CB342;
  background-color: #7CB342;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 15px;
  transition: transform 80ms ease-in;
}

.body-container button:hover{
  background-color: #73a83a;
  border: 1px solid #73a83a;
}

.body-container button:active {
  transform: scale(0.95);
}

.body-container button:focus {
  outline: none;
}

.body-container button.ghost {
  background-color: transparent;
  border-color: #FFFFFF;
}

.body-container form {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.body-container input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.body-container-prontocobro {
  position: relative;
  background-image: none;
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
  margin: -1px 0 50px;
}

.body-container-prontocobro * {
  box-sizing: border-box;
}

.body-container-prontocobro h1 {
  font-weight: bold;
  margin: 0;
}

.body-container-prontocobro a {
  position: relative;
  margin-top: 30px;
  top: 80px;
}

.body-container-prontocobro h2 {
  text-align: center;
}

.body-container-prontocobro p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.body-container-prontocobro span {
  font-size: 12px;
}

.body-container-prontocobro button {
  border-radius: 20px;
  border: 1px solid #757575;
  background-color: #757575;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 15px;
  transition: transform 80ms ease-in;
}

.body-container-prontocobro button:hover{
  background-color: #615f5f;
  border: 1px solid #615f5f;
}

.body-container-prontocobro button:active {
  transform: scale(0.95);
}

.body-container-prontocobro button:focus {
  outline: none;
}

.body-container-prontocobro button.ghost {
  background-color: transparent;
  border-color: #FFFFFF;
}

.body-container-prontocobro form {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.body-container-prontocobro input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  background-color: #fff;
  border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25),
      0 10px 10px rgba(0,0,0,0.22);
  position: relative;
  overflow: hidden;
  width: 900px;
  max-width: 110%;
  min-height: 580px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.form-container img{
  position: relative;
  width: 60%;
  bottom: 70px
}

.form-container span{
  position: relative;
  font-size: 20px;
  bottom: 15px;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%, 49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%, 100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.overlay-container img{
  position: relative;
  width: 100%;
  right: 8px;
}

@media (max-width: 900px) {
  .overlay-container {
    display: none !important;
  }
  .sign-in-container {
    width: 100%;
  }
}

.container.right-panel-active .overlay-container{
  transform: translateX(-100%);
}

.overlay {
  background: #7CB342;
  background: -webkit-linear-gradient(to right, #7CB342, #7CB342);
  background: linear-gradient(to right, #7CB342, #7CB342);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
    transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-prontocobro {
  background: #757575;
  background: -webkit-linear-gradient(to right, #757575, #757575);
  background: linear-gradient(to right, #757575, #757575);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
    transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
