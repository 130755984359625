.conversation-list {
  display: flex;
  flex-direction: column;
}
.conversation-list > .toolbar-title {
  align-self: center;
}

.close-dialog {
  margin-top: 0 !important;
}

.close-dialog > .p-dialog-titlebar-close-icon {
  font-weight: bold;
  color: #a7a7a7 !important;
  font-size: x-large;
}

.toolbar-action-buttons {
  display: inline-flex;
  padding: 0;
}

.close-custom-tag {
  width: 2em !important;
  height: 2em !important;
}

.custom-tag-get-back {
  color: #a7a7a7;
  margin-right: 0.5em;
}

.p-dialog-titlebar {
  border-bottom: 0 none !important;
  background: #ffffff !important;
  color: #495057 !important;
  padding: 1rem !important;
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
  border-bottom: 1px solid #bdbdbd !important;
}

.p-dialog-header {
  border-bottom: 1px solid #e4e4e4 !important;
  padding-bottom: 1em;
}

.mt-1 {
  margin-top: 1em;
}

.p-contextmenu {
  left: 10px !important;
  top: initial !important;
}

.wrapper-cell-loading {
  padding: 5px;
  display: flex;
  margin-bottom: 15px;
}

// Animation
@keyframes placeHolderShimmer{
   0%{
       background-position: -468px 0
   }
   100%{
       background-position: 468px 0
   }
}

.animated-background {
   animation-duration: 1.50s;
   animation-fill-mode: none;
   animation-iteration-count: infinite;
   animation-name: placeHolderShimmer;
   animation-timing-function: linear;
   background: #F6F6F6;
   background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
   background-size: 700px 104px;
   height: 100px;
   position: relative;
}

// Page Elements
.image {
left: 5px;
 height: 70px;
 width: 75px;
 border-radius: 50%;
    object-fit: cover;
    align-self: flex-start;
//  background: #F6F6F6;
 @extend .animated-background;
}

.text {
 margin-left: 15px
}

.text-line-big {
 height: 10px;
 width: 350px;
//  background: #F6F6F6;
 margin: 4px 0;
 -moz-border-radius: 5px;
-webkit-border-radius: 5px;
padding: 10px;
 @extend .animated-background;
}
@media (max-width: 1024px) {
  .text-line-big {
    width: 300px;
  }
}

@media (max-width: 750px) {
  .text-line-big {
    width: 600px;
  }
}
.text-line-small {
  height: 10px;
  width: 180px;
 //  background: #F6F6F6;
  margin: 4px 0;
  -moz-border-radius: 5px;
 -webkit-border-radius: 5px;
 padding: 10px;
  @extend .animated-background;
 }
 .text-line-medium {
  height: 10px;
  width: 280px;
 //  background: #F6F6F6;
  margin: 4px 0;
  -moz-border-radius: 5px;
 -webkit-border-radius: 5px;
 padding: 8px;
  @extend .animated-background;
 }

.fa-layers-counter {
  font-size: xxx-large;
  bottom: var(--fa-bottom, 0.25em);
  right: var(--fa-right, -0.5em)
}


/* Style the tab */
.toolbar-custom {
  //overflow: hidden;
  position: static;
  height: 50px !important;
  padding: 15px 7px 1px;
}

/* Style the buttons that are used to open the tab content */
.toolbar-custom .left-items {
  position: relative;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  //padding: 14px 16px;
  //transition: 0.3s;
  //width: 50%;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  //font-size: 15px;
}

/* Change background color of buttons on hover */
.toolbar-custom button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.toolbar-custom button.active {
  background-color: #ccc;
}

.toolbar-custom .tablinks {
  padding: 1px;
}

.init-conversation {
  display: inline-flex;
}

.init-conversation-main {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #eeeef1;
  border-right: none;
}

.init-conversation-icon {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  top: 5px;
  right: 14px;
}

.init-conversation-icon-true {
  cursor: pointer;
}

.init-conversation-icon-false {
  opacity: 0.5;
  cursor: not-allowed;
}

.search-filter {
  position: relative;
  display: flex;
  z-index: 2;
}

.user-tag-filter {
  position: relative;
  padding: 1px 10px 1px;
}
@media (min-width: 750px) {
  .menu-filter {
    position: absolute;
    margin-top: 11em;
    left: -235px;
    z-index: 1;
  }
}

@media (max-width: 750px) {
  .menu-filter {
    position: absolute;
    margin-top: 11em;
    left: -170px;
    z-index: 1;
  }
}

@media (min-width: 750px) {
  .list-drop {
    margin-left: 1em;
    margin-bottom: 1em;
    z-index: 1;
    flex: auto;
    max-width: 80%;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .list-drop {
    margin-left: 1em;
    margin-bottom: 1em;
    z-index: 1;
    flex: auto;
    max-width: 80%;
    display: flex;
    flex-direction: column;
  }
}

.conversation-contact-phone-invalid {
  width: 100%;
  color: red;
  text-align: center;
}
