.toolbar {
  display: flex;
  align-items: center;
  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
  position: sticky;
  top: 0px;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(1px);
  }
}

.toolbar-title {
  /*margin-left: 0.5em;*/
  /*margin-right: 0.5em;*/
  font-size: 18px;
  font-weight: 800;
}

.center-items{
  flex: 1;
  padding: 0.75em;
  display: flex;
  flex-flow: wrap;
}

.right-items-title{
  display: flex;
  flex-flow: wrap;
}

.right-items{
  flex: 1;
  padding: 0.75em;
  display: flex;
  flex-flow: wrap;
}

.left-items{
  left: 10px;
}

.left-items-title{
  flex: 1;
  padding: 0.75em;
  display: flex;
  flex-flow: wrap;
}


.toolbar-justify-items > .left-items {
  margin: 0.5em;
  justify-content: space-evenly;
}
.toolbar-justify-items > .right-items {
  margin: 0.5em;
  justify-content: space-evenly;
}

.toolbar-justify-items > .center-items {
  margin: 0.25em;
  justify-content: space-around;
}

.right-items {
  flex-direction: row-reverse;
  align-items: center;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 1;
}