.tab {
  display: flex;
  background-color: #f4f4f400;
  border: 1px solid #ccc;
  margin: 0.25em 0.75em 1em 0.75em;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.1);
}

.tab button {
  flex: 1;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 15px;
  background-color: transparent;
  text-align: center;
  color: black;
}

.tab button:hover {
  background-color: #e8e8e8;
}

.tab button.active {
  background-color: #7CB342;
  color: #fff;
  border-bottom: 3px solid #5F8C2B;
}

.tab .tablinks {
  padding: 4px;
}

.tab button:hover {
  transition: background-color 0.3s, color 0.3s, border-bottom 0.3s;
}


