.messenger {
  display: grid;
  width: 100%;
  background: #eeeef1;
  grid-template-columns: 450px auto;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

@media (min-height: 750px) {
  .content {
    height: 89vh;
  }
  .messenger {
    height: 91vh;
  }
}
@media (max-height: 750px) {
  .content {
    height: 82vh;
  }
  .messenger {
    height: 86.5vh;
  }
}


@media (max-width: 1024px) {
  .messenger {
    grid-template-columns: 400px auto;
  }
}
.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  width: 6px!important;
  height: 6px!important;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(153, 153, 156);
}

::-webkit-scrollbar-track {
  background: #fff;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 2;
}

.content {
  grid-row-start: 1;
  grid-row-end: span 2;
  background-image: url("../../../assets/images/whatsapp-background.png");
  background-color: #efeae2;
}

.footer {
  grid-column-start: 2;
  background: white;
}
.image-center img {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
}