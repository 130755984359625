.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #eeeef1;
  border-right: none;
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-list-item.active {
  background: #eeeef1;
}

.conversation-info{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: auto;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  align-self: flex-start;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.custom-tags {
  overflow: hidden;
  max-width:100%;
  /*display: -webkit-box;*/
  /*display: flow-root;*/
  display: inline-block;


  text-overflow: ellipsis;
  /*background-color: #00bcd4;*/
  background-color: green;
  border-radius: 9px;
  padding: 2px 8px;
  color: #ffffff;
  font-size: 14px;
  /*margin: 0.5em 0 0.5em 0;*/

}


.custom-tags-span {
  margin-right: 0.25em;
  margin-left: 0;
  margin-top: 0.25em;
  max-width: fit-content;

}

.more-tags {
  display: block;
  margin-left: -4.5em;
  margin-bottom: -0.4em;
  color: #00bcd4;
  align-self: flex-end;
  margin-top: 6em;
}

.right{
  position: absolute;
  right: 5px
}

.full-time{
  position: relative;
  margin-top: 5px;
  padding: 0px 0px 0px 0px;
  font-style: italic;
  font-size: 13px;
}