.conversation-search {
  padding: 0px 5px 5px 5px;
  flex-direction: column;
  width: 100%;
}
.conversation-search-input {
  padding: 10px 10px 8px 10px;
  background: #fff;
  border-radius: 10px;
  border: 2px solid #f4f4f8;
  font-size: 14px;
  width: 98%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.1);
}

.conversation-search-input:hover {
  border-color: #5F8C2B !important;
  box-shadow: 0 0 2px #5F8C2B !important;
}

.conversation-search-input:focus {
  border-color: black !important;
  box-shadow: 0 0 0px black !important;
}

.conversation-search-input:active {
  border-color: black !important;
}

.conversation-search-input:disabled {
  background: #f4f4f8;
  border-color: #ccc !important;
}

.conversation-search-input::placeholder {
  opacity: 0.5;
}

.conversation-search-input.loading {    
  background-color: #f4f4f8;
  background-image: url("../../../assets/images/loading.gif");
  background-size: 25px 25px;
  background-position:right center;
  background-repeat: no-repeat;
}

.conversation-search-input::placeholder {
  text-align: center;
}

.conversation-search-input:focus::placeholder {
  color: transparent;
}

.conversation-search .clean {
  cursor: pointer;
  right: 20px;
}

.conversation-search .clean:hover {
  color: #5F8C2B;
}
