@media (max-width: 1024px) {
    .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > button > a {
        display: block;
        color: #212121;
    }
}

@media (max-width: 1024px) {
    .layout-wrapper .layout-main .layout-topbar > img {
        max-width: 65%;
        max-height: 48px;
        height: auto!important;
        padding-top: 10px;
    }
}

.layout-wrapper .layout-main .layout-topbar {
    z-index: 5;
}