.compose {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  border-top: 1px solid #eeeef1;
  position: relative;
}

@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
    background-color: #fff;
  }
}

.compose-input {
  padding: 10px 10px 8px 10px;
  background: #fff;
  border-radius: 10px;
  border: 2px solid #f4f4f8;
  font-size: 14px;
  width: 98%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.1);
}

.compose-input:hover {
  border-color: #5F8C2B !important;
}

.compose-input:focus {
  border-color: black !important;
  box-shadow: 0 0 0px black !important;
}

.compose-input:disabled {
  background: #f4f4f8;
  border-color: #ccc !important;
}

.compose-input::placeholder {
  opacity: 0.5;
}

.compose textarea.compose-input {
  width: 97%;
  border: solid 3px #dcdddc;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  min-width: 99% !important;
  max-width: 99% !important;;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}

.compose .chat-loading {
  width: 97%;
  background-color: #eaeaec;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.note {
  background-color: #f5b961;
}

.reminder{
  background-color: #AE90DD;
}

.message-preview-context.context-compose {
  max-width: 97%;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -5.25em;
  position: absolute;
  display: inline-flex;
  background-color: #fff;
}

.forward-items-selection.context-compose {
  max-width: 7%;
  position: absolute;
  display: inline-flex;
}

.chatbox {
  width: 100%;
  max-width: 100%;
}
