.boolean-field-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
}
.boolean-field-badge.status-actived {
    background: #c8e6c9;
    color: #256029;
}

.boolean-field-badge.status-disabled {
    background: #ffcdd2;
    color: #c63737;
}

.p-row-group-header-chatbot {
    margin-top: -1.5em;
}

.btn-chatbot-actions {
    margin-right: 0.5em;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.datatable-filter-wa-mobile .p-paginator .p-paginator-current {
    margin-left: auto;
  }
  
  .datatable-filter-wa-mobile .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
  }
  
  .datatable-filter-wa-mobile .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
  }
  
  .datatable-filter-wa-mobile .table-header {
    display: flex;
    justify-content: space-between;
  }
  
  .datatable-filter-wa-mobile .p-datepicker {
    min-width: 25rem;
  }
  
  .datatable-filter-wa-mobile .p-datepicker td {
    font-weight: 400;
  }
  
  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }
  
  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
  }
  
  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
  }
  
  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
  }
  
  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
  
  .datatable-filter-wa-mobile .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }
  
  @media screen and (max-width: 750px) {
    .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
    .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
        display: none !important;
    }
  
    .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }
  
    .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
        text-align: center;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }
  
    .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }
  
    .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }
  }