.message-list-container {
  padding: 10px;
  padding-bottom: 70px;
}

.multiselect-custom-tags-list {
  max-width: 400px;
}
.multiselect-custom-tags-list > .p-multiselect-label-container {
  padding: 0;
}

.multiselect-custom-tags-list > .p-multiselect-panel > .p-multiselect-header  {
  padding: 0.75rem;
}


.multiselect-custom-tags-list .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.75rem;
}

.multiselect-custom-tags-list .p-multiselect-panel {
  min-width: 300px;
}

.multiselect-custom-user-group-list {
  max-width: 400px;
}
.multiselect-custom-user-group-list > .p-multiselect-label-container {
  padding: 0;
}

.multiselect-custom-user-group-list > .p-multiselect-panel > .p-multiselect-header  {
  padding: 0.75rem;
}


.multiselect-custom-user-group-list .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.75rem;
}

.multiselect-custom-user-group-list .p-multiselect-panel {
  min-width: 300px;
}

.my-multiselected-item-token {
  background: #455a64;
  color: #fff;
  padding: 2px 4px;
  margin: 0 0.286em 0 0;
  display: inline-block;
  vertical-align: middle;
  height: 1.857em;
  border-radius: 3px;
}

.message-list .toolbar.active {
  padding-bottom: 3em;
}
.messenger-toolbar {
  opacity: 1;
  background-color: #7cb34200;
  backdrop-filter: blur(10px);
}

.center {
  text-align: center;
}

.btn {
  width:30px;
  height:30px;
  border-radius:100%;
  color:#FFF;
  font-size:28px;
  padding: 6px;
  margin: 2px;
  transform:scale(1);
}

.btnEmoji {
  width:22px;
  height:22px;
  border-radius:100%;
  color:#FFF;
  font-size:28px;
  outline:none;
  padding: 6px;
  margin: 2px;
  transform:scale(1);
  cursor: pointer;
}

.botonF2 {
  width:22px;
  height:22px;
  background:#607D8B;
  transition:0.5s;
}

.botonF3 {
  width:22px;
  height:22px;
  background:#673AB7;
  transition:0.7s;
}

.botonF4 {
  width:22px;
  height:22px;
  background:#009688;
  transition:0.9s;
}

.botonF5{
  width:22px;
  height:22px;
  background:#FF5722;
  transition:0.99s;
}

.botonCancel{
  width:22px;
  height:22px;
  background:#63747c;
  transition:0.99s;
}

.botonSend{
  width:22px;
  height:22px;
  background:#009605;
  transition:0.99s;
}

.dropZone{
  position: relative;
  border: 15px dotted #bebebe;
  background-color: #FFF;
  width: 100%;
  height: 100%;
}

.dropZone h3 {
  color: #bebebe;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.context-items {
  max-width: 97%;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -5%;

}

.compose-element {
  display: grid;
  height: 5.5%;
}

.customTagsList{
  position: absolute;
  top: 4em;
  right: 9em;
}

.userList {
  position: absolute;
  top: 4em;
  right: 9.5em;
}

.userGroupList {
  position: absolute;
  top: 4em;
  right: 6em;
  width: auto;
}

.btn-forward {
  background:#009605;
  transition:0.99s;
  width:22px;
  height:22px;
  border-radius:100%;
  color:#FFF;
  font-size:28px;
  padding: 6px;
  margin: 2px;
}

.btn-forward-disabled {
  background:#607D8B;
  cursor: not-allowed;
}

.select-user-group {
  color: white;
  border: 2px dotted #7CB342;
  font-weight: bolder;
  padding: 5px 0;
  background: #7CB342;
  display:flex;
}

.footer-scheduled-message {
  text-align: center;
}

.footer-scheduled-message button{
  color: blue;
  margin-bottom: 1rem;
}

.btn-cancel-message-scheduled {
  background: red;
}
.btn-send-message-scheduled {
  cursor: pointer;
}

.btn-send-message-scheduled-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}