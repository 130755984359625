.file-right {
    text-align: right;
}
.file-card{
    background-color: #f8f8f8;
    grid-row-start: 1;
    grid-row-end: span 2;
    height: 87vh;
}
.datatable-custom{
    position: relative;
    padding: 25px 70px;
}
@media(max-height: 750px) {
	.file-card{
        position: relative;
        background-color: transparent;
  }
}