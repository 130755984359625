.p-button.p-button-pip {
    place-content: center;    
}

.p-button.p-button-pip-min {
    place-content: center;
}

.p-button.p-button-pip {
    background: #7CB342 !important
}

.p-button.p-button-pip:hover {
    background: #7CB342
}

.p-button.p-button-pip:active {
    background: #7CB342 
}

.p-button.p-button-pip-min {
    background: #558B2F !important
}

.p-button.p-button-pip-min-disabled {
    background: #bebebe !important
}

.p-button.p-button-pip-min:hover {
    background: #558B2F
}

.p-button.p-button-pip-min:active {
    background: #558B2F
}

.p-button-button {
    color:red
}

.sheet-textarea {
    resize: none;
}

.sheet-question {
    resize: none;
}

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-fluid .p-inputgroup .p-input {
    width: 100%;
}

.space-left{
    padding-left: 5px;
}

.space-right{
    padding-right: 8px;
}

.p-button-container {
    position: relative;
    top: 5px;
}

.sheet-qr {
    position: relative;
    top: 10px;
}
