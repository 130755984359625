.message-note-modal .p-dialog-header {
  background-color: #efeae2;
  opacity: 1;
}

.message-note-modal .p-dialog-content {
  grid-row-start: 1;
  grid-row-end: span 2;
  background-image: url("../../../assets/images/whatsapp-background.png");
  background-color: #efeae2;
  height: 85.5vh;
}

@media (min-height: 750px) {
  .message-note-modal .p-dialog-content {
    height: 89vh;
  }
}
@media (max-height: 750px) {
  .message-note-modal .p-dialog-content {
    height: 82vh;
  }
}

.message-note-modal-toolbar {
  background-color: #efeae2;
}
