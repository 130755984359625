.toolbar-button {
  color: #007aff;
  font-size: 15px;
  padding-top: 1em;
  transition: all 0.1s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #0063ce;
}

.toolbar-button:active {
  color: #007aff;
  opacity: 0.25;
}