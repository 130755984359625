  
.date-container .date {
    margin: 10px;
    background: #f8f8f8;
    padding: 1px 10px;
    border-radius: 50px;
    max-width: 100%;
    text-transform: uppercase;
}

.date-container .date span {
    color: #999;
    font-weight: 600;
    font-size: 10px;
    margin: 5px 0px;
    text-transform: uppercase;
}

.center {
    text-align: center;
}

.date {
    display: flex;
    align-items: center;
    justify-content: center;
}